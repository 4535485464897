import { useState } from "react";
import styled from "styled-components";
import { Badge, Button } from "react-bootstrap";

import {
  faBoxArchive,
  faCircleCheck,
  faCircleExclamation,
  faClone,
  faPenToSquare,
  faTrashCanArrowUp
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  TableRow,
  NameLink,
  FixedWidthDiv,
  TableDetailCell,
  TableDetailNameCell
} from "../common-styling";
import {
  archiveFunnel,
  unarchiveFunnel,
  duplicateFunnel
} from "../../services/api-declaration";
import { useDialog } from "../../hooks/useDialog";
import NoResultsRow from "../no-results-table-row";
import AbsoluteLoader from "../loader/absolute-loader";
import { useNotification } from "../../hooks/useNotification";
import GoToSiteButton from "../go-to-site-button/site-button";
import {
  showError,
  showSuccess,
  showWarning
} from "../../services/toast-service";
import {
  generateSiteUrl,
  generateDateTime,
  handleTestMode
} from "../../services/common-service";
import { Trans, useTranslation } from "react-i18next";

const FunnelTypeBadge = styled(Badge)`
  padding: 8px;
  min-width: 50%;
  text-transform: capitalize;
  color: ${({ btncolor }) => `var(${btncolor})`};
  background-color: ${({ btnbg }) => `var(${btnbg})`} !important;
`;
const StatusIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${({ status }) => `var(--${status}-status-color)`};
`;

const FunnelTableRow = ({ funnels, getFunnels, asSubComponent }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showToastWithLink } = useNotification();
  const [isDuplicating, setIsDuplicating] = useState(false);
  const {
    showConfirmationDialogBox,
    showAddDomainDialogBox,
    setDependentStates
  } = useDialog();
  const AMLogoPath = `${process.env.PUBLIC_URL}/app-logo.png`;

  const navigateToFunnel = ({ id, general: { funnelType } }) => {
    navigate({
      pathname: asSubComponent ? `../../../funnels/${id}/form` : `${id}/form`,
      search: createSearchParams({ funnelType }).toString()
    });
  };
  const handleArchive = async (funnelId) => {
    try {
      const response = await archiveFunnel(funnelId);
      showSuccess(response.data.message);
      await getFunnels();
    } catch (err) {
      console.log("ERROR ", err);
      showError(err.response?.data?.message || t("toastMessage.archiveError"));
    }
  };
  const handleUnarchive = async (funnelId, payload = {}) => {
    try {
      const response = await unarchiveFunnel(funnelId, payload);
      showSuccess(response.data.message);
      await getFunnels();
    } catch (err) {
      console.log("ERROR ", err);
      showError(
        err.response?.data?.message || t("toastMessage.unarchiveError")
      );
    }
  };
  const dialogResponseAction = (action, values) => {
    if (action) {
      handleArchive(values.selectedFunnelId);
    }
  };
  const renderDialogMessage = () => (
    <div>
      <Trans i18nKey="archiveFunnelMessage" />
    </div>
  );
  const unarchiveDialogMessage = (showHasDomain) => (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: t("modalContent.funnel.unarchiveFunnelMsg")
        }}
      ></div>
      {!showHasDomain && (
        <small>
          <b>NOTE:</b> {t("modalContent.funnel.note")}
        </small>
      )}
    </div>
  );
  const unarchiveResponseAction = (action, values) => {
    if (action) {
      if (values.hasDomain) {
        handleUnarchive(values.selectedFunnelId);
      } else {
        toggleAddDomainDialog(values.selectedFunnelId);
      }
    }
  };
  const handleUnarchiveModal = (_funnel) => {
    const { id, facebookForm, funnelQA, domain, subDomain } = _funnel;
    const hasSiteButtonUrl = domain && subDomain;
    let isDomainRequired = true;
    if (facebookForm?.page?.id && !funnelQA.length) {
      isDomainRequired = false; // domain & subdomain validation for facebook funnel
    }
    let shouldShow;
    if (!isDomainRequired || hasSiteButtonUrl) {
      shouldShow = true;
    } else {
      shouldShow = false;
    }
    setDependentStates({ selectedFunnelId: id, hasDomain: shouldShow });
    toggleUnarchiveConfirmation(shouldShow);
  };
  const toggleDuplicateConfirmationDialog = (_funnel) => {
    showConfirmationDialogBox({
      title: t("duplicateFunnelTitle"),
      dialogType: "primary",
      dialogMessage: _funnel.facebookForm.page
        ? duplicateDialogMessageForFB()
        : duplicateDialogMessage(),
      responseAction: duplicateResponseAction
    });
  };

  const toggleArchiveConfirmation = () =>
    showConfirmationDialogBox({
      title: `${t("common.archive")} Funnel`,
      dialogType: "danger",
      dialogMessage: renderDialogMessage(),
      responseAction: dialogResponseAction
    });
  const toggleUnarchiveConfirmation = (showHasDomain) =>
    showConfirmationDialogBox({
      title: t("modalContent.funnel.unarchiveFunnelTitle"), // Unarchive funnel
      dialogType: "primary",
      dialogMessage: unarchiveDialogMessage(showHasDomain),
      responseAction: unarchiveResponseAction
    });
  const toggleAddDomainDialog = (resourceId) =>
    showAddDomainDialogBox({
      getFunnels,
      resourceId
    });
  const displayLinkToast = ({ data, toastType, toastMsg }) =>
    showToastWithLink({
      url: `funnels/${data.id}/form?funnelType=${data.general.funnelType}`,
      msg: toastMsg,
      showToast: toastType
    });
  const duplicateResponseAction = async (action, values) => {
    if (action) {
      try {
        setIsDuplicating(true);
        const response = await duplicateFunnel(values.selectedFunnelId, {
          createDefaultEmailIntegration: true
        });
        const data = response.data;
        if (data) {
          displayLinkToast({
            data,
            toastType: showSuccess,
            toastMsg: t("toastMessage.duplicateFunnelSuccess")
          });
        }
        setIsDuplicating(false);
      } catch (error) {
        setIsDuplicating(false);
        showError(
          error.response.data.message || t("toastMessage.duplicateFunnelError")
        );
        console.log("Error in duplicate funnel ", error);
      }
    }
  };
  const duplicateDialogMessage = () => (
    <Trans i18nKey="duplicateFunnelMessage" />
  );

  const duplicateDialogMessageForFB = () => (
    <Trans i18nKey="duplicateFunnelMessageForFB" />
  );

  const generateLinkUrl = ({ id, general: { funnelType } }) => {
    let queryParam = `funnelType=${funnelType}`;
    return asSubComponent
      ? `../../../funnels/${id}/form?${queryParam}`
      : `${id}/form?${queryParam}`;
  };
  const isValidFunnel = (_funnel) =>
    _funnel.customer &&
    _funnel.category &&
    (_funnel.facebookForm?.page?.id ||
      (_funnel.funnelQA.length && _funnel.subDomain));

  const handleDuplicateClick = (_funnel) => {
    if (isValidFunnel(_funnel)) {
      setDependentStates({ selectedFunnelId: _funnel.id });
      toggleDuplicateConfirmationDialog(_funnel);
    } else {
      displayLinkToast({
        data: _funnel,
        toastType: showWarning,
        toastMsg: t("toastMessage.duplicateFunnelWarning")
      });
    }
  };

  return funnels.length ? (
    <>
      {funnels.map((funnel, index) => (
        <TableRow key={index}>
          <TableDetailNameCell
            className={`py-3 align-middle ${funnel.archived ? "pe-none" : ""}`}
          >
            <div className="d-flex align-items-center">
              <div className="flex-fill">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{funnel.general.funnelName}</Tooltip>}
                >
                  <NameLink className="d-block" to={generateLinkUrl(funnel)}>
                    {funnel.general.funnelName}
                  </NameLink>
                </OverlayTrigger>
              </div>
            </div>
          </TableDetailNameCell>
          <TableDetailCell className="py-3 align-middle fw-bold">
            <div className="d-flex align-items-center">
              {funnel.facebookForm?.page?.id && (
                <FontAwesomeIcon
                  fontSize={20}
                  color="#1a74e4"
                  icon={faFacebook}
                />
              )}
              {funnel.funnelQA.length ? (
                <FixedWidthDiv width="28">
                  <img className="w-100" src={AMLogoPath} alt="cockpit-logo" />
                </FixedWidthDiv>
              ) : (
                <></>
              )}
            </div>
          </TableDetailCell>
          <TableDetailCell className="py-3 align-middle text-center fw-bold">
            {funnel.leadsCount}
          </TableDetailCell>
          <TableDetailCell className="py-3 align-middle">
            <FunnelTypeBadge
              btnbg={`--${funnel.general.funnelType.toLowerCase()}-badge-bg`}
              btncolor={`--${funnel.general.funnelType.toLowerCase()}-color`}
            >
              {funnel.general.funnelType.toLowerCase()}
            </FunnelTypeBadge>
          </TableDetailCell>
          <TableDetailCell className="py-3 align-middle">
            {funnel.general.template || "---"}
          </TableDetailCell>
          <TableDetailCell className="py-3 align-middle">
            {funnel.updatedAt
              ? generateDateTime(funnel.updatedAt)
              : generateDateTime(funnel.createdAt)}
          </TableDetailCell>
          <TableDetailCell className="py-3 align-middle text-center">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  {funnel.activeIntegrationsCount > 0 ? "Active" : "Non Active"}
                </Tooltip>
              }
            >
              {funnel.activeIntegrationsCount > 0 ? (
                <StatusIcon icon={faCircleCheck} status="active" />
              ) : (
                <StatusIcon icon={faCircleExclamation} status="non-active" />
              )}
            </OverlayTrigger>
          </TableDetailCell>
          <TableDetailCell className="py-3 text-center">
            {funnel.archived ? (
              <div className="d-flex justify-content-end">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{t("common.unarchive")}</Tooltip>}
                >
                  <Button
                    size="sm"
                    className="me-2"
                    variant="outline-primary rounded-circle"
                    onClick={() => handleUnarchiveModal(funnel)}
                  >
                    <FontAwesomeIcon size="sm" icon={faTrashCanArrowUp} />
                  </Button>
                </OverlayTrigger>
              </div>
            ) : (
              <div className="d-flex">
                <Button
                  size="sm"
                  className="me-2"
                  variant="outline-primary rounded-circle"
                  onClick={() => navigateToFunnel(funnel)}
                >
                  <FontAwesomeIcon size="sm" icon={faPenToSquare} />
                </Button>
                <Button
                  size="sm"
                  className="me-2"
                  variant="outline-danger rounded-circle"
                  onClick={() => {
                    setDependentStates({ selectedFunnelId: funnel.id });
                    toggleArchiveConfirmation();
                  }}
                >
                  <FontAwesomeIcon size="sm" icon={faBoxArchive} />
                </Button>
                <Button
                  size="sm"
                  className="me-2"
                  variant="outline-secondary rounded-circle"
                  onClick={() => handleDuplicateClick(funnel)}
                >
                  <FontAwesomeIcon size="sm" icon={faClone} />
                </Button>
                <GoToSiteButton
                  variantSize="sm"
                  url={handleTestMode(
                    generateSiteUrl(funnel.domain, funnel.subDomain)
                  )}
                  isArchived={funnel.archived}
                  isTestMode={true}
                />
              </div>
            )}
          </TableDetailCell>
        </TableRow>
      ))}
      {isDuplicating && (
        // tr and td is just included to avoid console warning because div can't be appear as tbody's child
        <tr>
          <td>
            <AbsoluteLoader loaderMessage={t("duplicateLoaderMessage")} />
          </td>
        </tr>
      )}
    </>
  ) : (
    <NoResultsRow lengthOfColumns="8" />
  );
};
export default FunnelTableRow;
