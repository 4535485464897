import {
  BASE_COUNTRY,
  SEARCH_LEAD_BY,
  FB_CONVERSION_API_OPTS
} from "../strings";

export const countryOptions = [
  { name: "Deutschland", _id: BASE_COUNTRY },
  { name: "Frankreich", _id: "france" },
  { name: "Belgien", _id: "belgium" },
  { name: "Italien", _id: "italy" },
  { name: "Luxemburg", _id: "luxemburg" },
  { name: "Niederlande", _id: "netherlands" },
  { name: "Schweiz", _id: "switzerland" },
  { name: "Österreich", _id: "austria" },
  { name: "Polen", _id: "poland" },
  {
    name: "Deutschland & Österreich & Schweiz",
    _id: "germanyAndAustriaAndSwitzerland"
  },
  { name: "Deutschland & Frankreich", _id: "germanyAndfrance" },
  { name: "Deutschland & Italien", _id: "germanyAnditaly" },
  { name: "Deutschland & Luxemburg", _id: "germanyAndluxemburg" },
  { name: "Deutschland & Niederlande", _id: "germanyAndnetherlands" },
  { name: "Deutschland & Österreich", _id: "germanyAndaustria" },
  { name: "Deutschland & Schweiz", _id: "germanyAndswitzerland" }
];
export const pixelOptions = [
  FB_CONVERSION_API_OPTS.WAREHOUSES,
  FB_CONVERSION_API_OPTS.GMBHS
];
export const domainList = [
  "dein-terrassendach.de",
  "kaffeevollautomaten-vergleichen.de",
  "deine-traumkueche.de",
  "virtueller-rundgang-360.de",
  "treppenlift-online.com",
  "carport-aktuell.de",
  "dein-traumpool.de",
  "spanndecken-vergleich.de",
  "deine-traumkueche.ch",
  "deine-badsanierung.de",
  "angebote-ums-haus.de",
  "dein-neuerjob.de",
  "deine-sonnenenergie.de",
  "deine-sicherheitstechnik.de",
  "traumkueche-angebot.de",
  // "hörgeräte-himmelsthür.de",
  "dein-traum-wintergarten.de",
  "deine-sanierung.de",
  "deine-sanierung.ch",
  "traum-buero.de",
  "angebote-ums-haus.at",
  "dein-neuerjob.at",
  "deine-klimatechnik.de",
  "deine-traumkueche.at",
  "b2bkonzepte.de",
  "traum-haustuer.de",
  "angebote-ums-haus.ch",
  "dein-neuerjob.ch",
  "regionale-kunden.de",
  "industrie-angebote.de",
  "dein-terrassendach.at"
];

export const designObj = {
  backgroundImage: null,
  companyLogo: null,
  partnerLogo: null,
  partnerLogoDisplay: true,
  baseColor: "#9F285A",
  companyLogoBackground: "#abc123",
  companyLogoBackgroundDisplay: true,
  footerForeground: "#000000",
  companyLogoScale: "100",
  modernBackgroundOne: "#000000",
  modernBackgroundTwo: "#000000",
  modernContrastColor: "#000000",
  modernHeaderLabelsColor: "#000000",
  modernHeadline: "",
  modernFunnelHeadline: "",
  additionalTaglineLabelOne: "",
  additionalTaglineLabelTwo: "",
  additionalTaglineColor: "#ffffff",
  additionalTaglineBackground: "#000000",
  modernHeaderCallLabel: "",
  modernHeaderPhoneNumber: null,
  modernHeaderEmailAddress: "",
  modernNextButtonText: "",
  modernNextButtonTextColor: "#000000",
  modernNextButtonBackground: "#000000",
  modernNextButtonHoverBackground: "#000000",
  modernSubmitButtonText: "",
  modernSubmitButtonTextColor: "#000000",
  modernSubmitButtonBackground: "#000000",
  modernSubmitButtonHoverBackground: "#000000",
  modernFormInfoLabelOne: "",
  modernFormInfoLabelTwo: "",
  modernFormInfoLabelThree: "",
  modernFormHeading: "",
  modernFormPolicyDisplay: true,
  uspIcons: []
};
export const textElemObj = {
  nextButtonText: "Weiter",
  nextButtonBackground: "#0d6efd",
  waitingScreen: "",
  zipCodeScreen: "",
  waitingScreenTwo: "",
  formScreenHeader: "",
  formScreenSubtext: "",
  formHeadline: "",
  subHeadlineForm: "",
  zipCodeScreenButton: "",
  fieldTitleName: "",
  fieldPlaceholderName: "",
  fieldTitleEmail: "",
  fieldPlaceholderEmail: "",
  fieldTitlePhone: "",
  fieldPlaceholderPhone: "",
  submitButton: "",
  submitButtonSubtext: "",
  numberCheck: ""
};
export const thankYouPageObj = {
  redirectUrl: "",
  websiteButton: "",
  websiteButtonText: "Webseite öffnen",
  thankyouPageHeadline: "",
  thankyouPageText: "",
  greetingPhrase: ""
};

export const INITITIAL_LOADING_DATA = {
  isLoading: false,
  loadingText: ""
};

export const advertisingAccountOptions = [
  { name: "A&M Unternehmerberatung GmbH #1", _id: "act_2214719712102223" }
];

export const countryCodesHash = {
  switzerland: "+41",
  austria: "+43",
  italy: "+39",
  netherlands: "+31",
  luxemburg: "+352",
  poland: "+48"
};

export const countryFlagEmojiHash = {
  switzerland: "🇨🇭",
  austria: "🇦🇹",
  italy: "🇮🇹",
  netherlands: "🇳🇱",
  luxemburg: "🇱🇺",
  poland: "🇵🇱"
};

export const leadSearchOptions = {
  PHONE: SEARCH_LEAD_BY.PHONE,
  EMAIL: SEARCH_LEAD_BY.EMAIL,
  FUNNEL: SEARCH_LEAD_BY.FUNNEL
};
